import { TOKEN_BLACKLIST_BSC,TOKEN_BLACKLIST_MAINNETZ, TOKEN_BLACKLIST_BSC_TESTNET, TOKEN_BLACKLIST_ETHEREUM, TOKEN_BLACKLIST_GOERLI, ZIS_ETH_START, ZIS_BSC_START, ZIS_GOR_START, ZIS_TBSC_START,ZIS_NETZ_START } from 'config/constants/info';
import { BLOCKS_CLIENT_BSC,BLOCKS_CLIENT_MAINNETZ,INFO_CLIENT_MAINNETZ, BLOCKS_CLIENT_BSC_TESTNET, BLOCKS_CLIENT_ETHEREUM, BLOCKS_CLIENT_GOERLI, INFO_CLIENT_BSC, INFO_CLIENT_BSC_TESTNET, INFO_CLIENT_ETHEREUM, INFO_CLIENT_GOERLI } from 'config/constants/endpoints'
import { infoClientBsc, infoClientBscTestnet, infoClientEthereum, infoClientGoerli, infoClientMainnetz } from 'utils/graphql'

import { ChainId } from '@zinuswap/sdk'

export type MultiChainName = 'NETZ' |'bETH' | 'BSC' | 'tBSC' | 'ETH' | 'GOR'

export const multiChainQueryMainToken = {
  NETZ : 'ETH',
  BSC: 'BNB',
  tBSC: 'tBSC',
  ETH: 'ETH',
  GOR: 'GOR',
  bETH: 'ETH'
}

export const multiChainBlocksClient = {
  NETZ: BLOCKS_CLIENT_MAINNETZ,
  BSC: BLOCKS_CLIENT_BSC,
  tBSC: BLOCKS_CLIENT_BSC_TESTNET,
  ETH: BLOCKS_CLIENT_ETHEREUM,
  GOR: BLOCKS_CLIENT_GOERLI,
}

export const multiChainStartTime = {
  NETZ : ZIS_NETZ_START,
  BSC: ZIS_BSC_START,
  tBSC: ZIS_TBSC_START,
  ETH: ZIS_ETH_START,
  GOR: ZIS_GOR_START,
}

export const multiChainId = {
  bETH: ChainId.BASE,
  NETZ: ChainId.MAINNETZ,
  BSC: ChainId.BSC,
  tBSC: ChainId.BSC_TESTNET,
  ETH: ChainId.ETHEREUM,
  GOR: ChainId.GOERLI,
}

export const multiChainPaths = {
  [ChainId.MAINNETZ]: '/netz',
  [ChainId.BSC]: '',
  [ChainId.BSC_TESTNET]: '/eth',
  [ChainId.ETHEREUM]: '/eth',
  [ChainId.GOERLI]: '/eth',
}

export const multiChainQueryClient = {

  NETZ: infoClientMainnetz,
  BSC:  infoClientBsc,
  tBSC: infoClientBscTestnet,
  ETH:  infoClientEthereum,
  GOR:  infoClientGoerli,
}

export const multiChainQueryClientByChainId = {
  [ChainId.MAINNETZ]: infoClientMainnetz,
  [ChainId.BSC]: infoClientBsc,
  [ChainId.BSC_TESTNET]: infoClientBscTestnet,
  [ChainId.ETHEREUM]: infoClientEthereum,
  [ChainId.GOERLI]: infoClientGoerli,
}

export const multiChainQueryEndPoint = {
  NETZ: INFO_CLIENT_MAINNETZ,
  BSC:  INFO_CLIENT_BSC,
  tBSC: INFO_CLIENT_BSC_TESTNET,
  ETH: INFO_CLIENT_ETHEREUM,
  GOR: INFO_CLIENT_GOERLI,
}

export const multiChainScan = {
  NETZ : 'Mainnetz',
  BSC: 'BscScan',
  tBSC: 'BscScan Testnet',
  ETH: 'EtherScan',
  GOR: 'Goerli Testnet',
}

export const multiChainTokenBlackList = {
  NETZ: TOKEN_BLACKLIST_MAINNETZ,
  BSC: TOKEN_BLACKLIST_BSC,
  tBSC: TOKEN_BLACKLIST_BSC_TESTNET,
  ETH: TOKEN_BLACKLIST_ETHEREUM,
  GOR: TOKEN_BLACKLIST_GOERLI,
}

export const getMultiChainQueryEndPoint = (chainName: MultiChainName) => {

  console.log("chainName")
  console.log(chainName)
  return multiChainQueryClient[chainName]
}
