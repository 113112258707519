import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { useMatchBreakpoints } from "@zinuswap/uikit";
import { ChainId } from "@zinuswap/sdk";
import { useExchangeChartManager } from "state/user/hooks";
import { useActiveChainId } from "hooks/useActiveChainId";

export const SwapFeaturesContext = createContext<{
  isChartSupported: boolean;
  isAccessTokenSupported: boolean;
  isChartExpanded: boolean;
  isChartDisplayed: boolean;
  setIsChartExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  setIsChartDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isChartSupported: false,
  isAccessTokenSupported: false,
  isChartExpanded: false,
  isChartDisplayed: false,
  setIsChartExpanded: null,
  setIsChartDisplayed: null,
});

const CHART_SUPPORT_CHAIN_IDS = [ChainId.BSC_TESTNET, ChainId.GOERLI]; // TODO-SD
const ACCESS_TOKEN_SUPPORT_CHAIN_IDS = [ChainId.BSC]; // TODO-SD

export const SwapFeaturesProvider: React.FC<
  React.PropsWithChildren
> = ({ children }) => {
  const { isMobile } = useMatchBreakpoints();
  const { chainId } = useActiveChainId();
  const [userChartPreference, setUserChartPreference] =
    useExchangeChartManager(isMobile);
  const [isChartDisplayed, setIsChartDisplayed] = useState(
    userChartPreference
  );
  const [isChartExpanded, setIsChartExpanded] = useState(false);

  const isChartSupported = useMemo(
    () =>
      // avoid layout shift, by default showing
      !chainId || CHART_SUPPORT_CHAIN_IDS.includes(chainId),
    [chainId]
  );

  const isAccessTokenSupported = useMemo(
    () => ACCESS_TOKEN_SUPPORT_CHAIN_IDS.includes(chainId),
    [chainId]
  );

  useEffect(() => {
    setUserChartPreference(isChartDisplayed);
  }, [isChartDisplayed, setUserChartPreference]);

  const value = useMemo(() => {
    return {
      isChartSupported,
      isAccessTokenSupported,
      isChartDisplayed,
      setIsChartDisplayed,
      isChartExpanded,
      setIsChartExpanded,
    };
  }, [
    isChartSupported,
    isAccessTokenSupported,
    isChartDisplayed,
    setIsChartDisplayed,
    isChartExpanded,
    setIsChartExpanded,
  ]);

  return (
    <SwapFeaturesContext.Provider value={value}>
      {children}
    </SwapFeaturesContext.Provider>
  );
};
