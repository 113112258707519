import { BIT_QUERY, INFO_CLIENT_BSC, INFO_CLIENT_BSC_TESTNET, INFO_CLIENT_ETHEREUM, INFO_CLIENT_GOERLI, INFO_CLIENT_MAINNETZ } from 'config/constants/endpoints'
import { GraphQLClient } from 'graphql-request'
import { INFO_CLIENT_WITH_CHAIN } from '../config/constants/endpoints'

// Extra headers
// Mostly for dev environment
// No production env check since production preview might also need them
export const getGQLHeaders = (endpoint: string) => {
  // if (endpoint === INFO_CLIENT_BSC) {
  //   return {
  //     'X-Sf':
  //       process.env.NEXT_PUBLIC_SF_HEADER ||
  //       // hack for inject CI secret on window
  //       (typeof window !== 'undefined' &&
  //         // @ts-ignore
  //         window.sfHeader),
  //   }
  // }
  return undefined
}
export const infoClientMainnetz = new GraphQLClient(INFO_CLIENT_MAINNETZ)
export const infoClientBsc = new GraphQLClient(INFO_CLIENT_BSC)
export const infoClientBscTestnet = new GraphQLClient(INFO_CLIENT_BSC_TESTNET)
export const infoClientEthereum = new GraphQLClient(INFO_CLIENT_ETHEREUM)
export const infoClientGoerli = new GraphQLClient(INFO_CLIENT_GOERLI)

export const infoClientWithChain = (chainId: number) => {
  return new GraphQLClient(INFO_CLIENT_WITH_CHAIN[chainId], { headers: getGQLHeaders(INFO_CLIENT_WITH_CHAIN[chainId]) })
}

export const infoServerClient = new GraphQLClient(INFO_CLIENT_BSC, {
  headers: {
    'X-Sf': process.env.SF_HEADER,
  },
  timeout: 5000,
})

export const bitQueryServerClient = new GraphQLClient(BIT_QUERY, {
  headers: {
    // only server, no `NEXT_PUBLIC` not going to expose in client
    'X-API-KEY': process.env.BIT_QUERY_HEADER,
  },
  timeout: 5000,
})
