import { Currency } from "@zinuswap/sdk";
import {
  BottomDrawer,
  Flex,
  useMatchBreakpoints,
} from "@zinuswap/uikit";
import { AppBody } from "components/App";
import { useContext } from "react";

import { useCurrency } from "../../hooks/Tokens";
import { Field } from "../../state/swap/actions";
import {
  useSingleTokenSwapInfo,
  useSwapState,
} from "../../state/swap/hooks";
import Page from "../Page";
import PriceChartContainer from "./components/Chart/PriceChartContainer";
import SwapForm from "./components/SwapForm";
import SwapTab from "./components/SwapTab";
import {
  StyledInputCurrencyWrapper,
  StyledSwapContainer,
} from "./styles";
import { SwapFeaturesContext } from "./SwapFeaturesContext";

export default function Swap() {
  const { isMobile } = useMatchBreakpoints();
  const {
    isChartExpanded,
    isChartDisplayed,
    setIsChartDisplayed,
    setIsChartExpanded,
    isChartSupported,
  } = useContext(SwapFeaturesContext);

  // swap state & price data
  const {
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState();
  const inputCurrency = useCurrency(inputCurrencyId);
  const outputCurrency = useCurrency(outputCurrencyId);

  const currencies: { [field in Field]?: Currency } = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined,
  };

  const singleTokenPrice = useSingleTokenSwapInfo(
    inputCurrencyId,
    inputCurrency,
    outputCurrencyId,
    outputCurrency
  );

  return (
    <Page
      removePadding={isChartExpanded}
      hideFooterOnDesktop={isChartExpanded}
    >
      <Flex
        width={["328px", , "100%"]}
        height="100%"
        alignItems="center"
        justifyContent="center"
        position="absolute"
      >
        {!isMobile && isChartSupported && (
          <PriceChartContainer
            inputCurrencyId={inputCurrencyId}
            inputCurrency={currencies[Field.INPUT]}
            outputCurrencyId={outputCurrencyId}
            outputCurrency={currencies[Field.OUTPUT]}
            isChartExpanded={isChartExpanded}
            setIsChartExpanded={setIsChartExpanded}
            isChartDisplayed={isChartDisplayed}
            currentSwapPrice={singleTokenPrice}
          />
        )}
        {isChartSupported && (
          <BottomDrawer
            content={
              <PriceChartContainer
                inputCurrencyId={inputCurrencyId}
                inputCurrency={currencies[Field.INPUT]}
                outputCurrencyId={outputCurrencyId}
                outputCurrency={currencies[Field.OUTPUT]}
                isChartExpanded={isChartExpanded}
                setIsChartExpanded={setIsChartExpanded}
                isChartDisplayed={isChartDisplayed}
                currentSwapPrice={singleTokenPrice}
                isMobile
              />
            }
            isOpen={isChartDisplayed}
            setIsOpen={setIsChartDisplayed}
          />
        )}
        <Flex
          flexDirection="column"
          alignItems="center"
          style={{ gap: "25px" }}
        >
          <h1>Trade tokens in an instant</h1>
          <StyledSwapContainer $isChartExpanded={isChartExpanded}>
            <StyledInputCurrencyWrapper
              mt={isChartExpanded ? "24px" : "0"}
            >
              <AppBody>
                <SwapTab>
                  <SwapForm />
                </SwapTab>
              </AppBody>
            </StyledInputCurrencyWrapper>
          </StyledSwapContainer>
        </Flex>
      </Flex>
    </Page>
  );
}
