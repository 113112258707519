import { getAddress } from '@ethersproject/address'
import memoize from 'lodash/memoize'
import { ChainId, Token } from '@zinuswap/sdk'

const mapping = {
  [ChainId.MAINNETZ]:'mainnetz',
  [ChainId.BSC]: 'smartchain',
  [ChainId.ETHEREUM]: 'ethereum',
}

const getTokenLogoURL = memoize(
  (token?: Token) => {
    if (token) {
      switch (token.chainId) {
        case 2016:
          // Add specific logic for chainId 2016
          return `https://raw.githubusercontent.com/MNZChain/DEX-Assets/main/assets/${getAddress(token.address)}.png`;
        
        case 9768:
          // Add specific logic for chainId 9768
          return `https://example.com/path_for_chainId_9768/${getAddress(token.address)}/logo.png`;
        
        default:
          if (mapping[token.chainId]) {
            return `https://assets-cdn.trustwallet.com/blockchains/${mapping[token.chainId]}/assets/${getAddress(token.address)}/logo.png`;
          }
          return null;
      }
    }
    return null;
  },
  (t) => `${t.chainId}#${t.address}`,
);



// const getTokenLogoURL = memoize(
//   (token?: Token) => {
    
//     if (token && mapping[token.chainId]) {
//       return `https://assets-cdn.trustwallet.com/blockchains/${mapping[token.chainId]}/assets/${getAddress(
//         token.address,
//       )}/logo.png`
//     }
//     return null
//   },
//   (t) => `${t.chainId}#${t.address}`,
// )

export default getTokenLogoURL
