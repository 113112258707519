import { useTranslation } from "@zinuswap/localization";
import {
  Currency,
  CurrencyAmount,
  NATIVE,
  Percent,
} from "@zinuswap/sdk";
import {
  ArrowDownIcon,
  Box,
  Button,
  Skeleton,
  Swap as SwapUI,
} from "@zinuswap/uikit";
import UnsupportedCurrencyFooter from "components/UnsupportedCurrencyFooter";
import { useIsTransactionUnsupported } from "hooks/Trades";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSwapActionHandlers } from "state/swap/useSwapActionHandlers";
import { maxAmountSpend } from "utils/maxAmountSpend";

import CurrencyInputPanel from "components/CurrencyInputPanel";
import { AutoColumn } from "components/Layout/Column";
import { AutoRow } from "components/Layout/Row";

import { useCurrency } from "hooks/Tokens";
import {
  ApprovalState,
  useApproveCallbackFromTrade,
} from "hooks/useApproveCallback";
import useWrapCallback, { WrapType } from "hooks/useWrapCallback";

import { Field } from "state/swap/actions";
import { useDerivedSwapInfo, useSwapState } from "state/swap/hooks";
import {
  useExpertModeManager,
  useUserSlippageTolerance,
} from "state/user/hooks";

import replaceBrowserHistory from "@zinuswap/utils/replaceBrowserHistory";
import { currencyId } from "utils/currencyId";

import { useAtomValue } from "jotai";
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
} from "utils/exchange";
import { combinedTokenMapFromOfficialsUrlsAtom } from "../../../state/lists/hooks";
import useRefreshBlockNumberID from "../hooks/useRefreshBlockNumber";
import useWarningImport from "../hooks/useWarningImport";
import AddressInputPanel from "./AddressInputPanel";
import AdvancedSwapDetailsDropdown from "./AdvancedSwapDetailsDropdown";
import CurrencyInputHeader from "./CurrencyInputHeader";
import { ArrowWrapper, Wrapper } from "./styleds";
import SwapCommitButton from "./SwapCommitButton";

export default function SwapForm() {
  const { t } = useTranslation();
  const { refreshBlockNumber, isLoading } = useRefreshBlockNumberID();
  const warningSwapHandler = useWarningImport();
  const tokenMap = useAtomValue(
    combinedTokenMapFromOfficialsUrlsAtom
  );

  const { account, chainId } = useActiveWeb3React();

  // for expert mode
  const [isExpertMode] = useExpertModeManager();

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance();

  // swap state & price data
  const {
    independentField,
    typedValue,
    recipient,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState();
  const inputCurrency = useCurrency(inputCurrencyId);
  const outputCurrency = useCurrency(outputCurrencyId);

  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency ?? undefined,
      [Field.OUTPUT]: outputCurrency ?? undefined,
    }),
    [inputCurrency, outputCurrency]
  );

  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    inputError: swapInputError,
  } = useDerivedSwapInfo(
    independentField,
    typedValue,
    inputCurrency,
    outputCurrency,
    recipient
  );

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const trade = showWrap ? undefined : v2Trade;
  const slippageAdjustedAmounts = trade
    ? computeSlippageAdjustedAmounts(v2Trade, allowedSlippage)
    : undefined;
  const { priceImpactWithoutFee, realizedLPFee } =
    computeTradePriceBreakdown(v2Trade);

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]:
          independentField === Field.INPUT
            ? parsedAmount
            : trade?.inputAmount,
        [Field.OUTPUT]:
          independentField === Field.OUTPUT
            ? parsedAmount
            : trade?.outputAmount,
      };

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  } = useSwapActionHandlers();

  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
    },
    [onUserInput]
  );
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput]
  );

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ""
      : parsedAmounts[dependentField]?.toSignificant(6) ?? "",
  };

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(
    trade,
    allowedSlippage,
    chainId
  );

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] =
    useState<boolean>(false);

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  const maxAmountInput: CurrencyAmount<Currency> | undefined =
    maxAmountSpend(currencyBalances[Field.INPUT]);
  const atMaxAmountInput = Boolean(
    maxAmountInput &&
      parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput)
  );

  const handleInputSelect = useCallback(
    (newCurrencyInput) => {
      setApprovalSubmitted(false); // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, newCurrencyInput);

      warningSwapHandler(newCurrencyInput);

      const newCurrencyInputId = currencyId(newCurrencyInput);
      if (newCurrencyInputId === outputCurrencyId) {
        replaceBrowserHistory("outputCurrency", inputCurrencyId);
      }
      replaceBrowserHistory("inputCurrency", newCurrencyInputId);
    },
    [
      inputCurrencyId,
      outputCurrencyId,
      onCurrencySelection,
      warningSwapHandler,
    ]
  );

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact());
    }
  }, [maxAmountInput, onUserInput]);

  const handleOutputSelect = useCallback(
    (newCurrencyOutput) => {
      onCurrencySelection(Field.OUTPUT, newCurrencyOutput);
      warningSwapHandler(newCurrencyOutput);

      const newCurrencyOutputId = currencyId(newCurrencyOutput);
      if (newCurrencyOutputId === inputCurrencyId) {
        replaceBrowserHistory("inputCurrency", outputCurrencyId);
      }
      replaceBrowserHistory("outputCurrency", newCurrencyOutputId);
    },

    [
      inputCurrencyId,
      outputCurrencyId,
      onCurrencySelection,
      warningSwapHandler,
    ]
  );

  const handlePercentInput = useCallback(
    (percent) => {
      if (maxAmountInput) {
        onUserInput(
          Field.INPUT,
          maxAmountInput.multiply(new Percent(percent, 100)).toExact()
        );
      }
    },
    [maxAmountInput, onUserInput]
  );

  const swapIsUnsupported = useIsTransactionUnsupported(
    currencies?.INPUT,
    currencies?.OUTPUT
  );

  const hasAmount = Boolean(parsedAmount);

  const onRefreshPrice = useCallback(() => {
    if (hasAmount) {
      refreshBlockNumber();
    }
  }, [hasAmount, refreshBlockNumber]);

  return (
    <>
      <CurrencyInputHeader
        title={t("Swap")}
        subtitle={t("Trade tokens in an instant")}
        hasAmount={hasAmount}
        onRefreshPrice={onRefreshPrice}
      />
      <Wrapper id="swap-page" style={{ minHeight: "412px" }}>
        <AutoColumn gap="lg">
          <CurrencyInputPanel
            label={
              independentField === Field.OUTPUT && !showWrap && trade
                ? t("From (estimated)")
                : t("From")
            }
            value={formattedAmounts[Field.INPUT]}
            showMaxButton={!atMaxAmountInput}
            showQuickInputButton
            currency={currencies[Field.INPUT]}
            onUserInput={handleTypeInput}
            onPercentInput={handlePercentInput}
            onMax={handleMaxInput}
            onCurrencySelect={handleInputSelect}
            otherCurrency={currencies[Field.OUTPUT]}
            id="swap-currency-input"
            showCommonBases
            showBUSD={
              !!tokenMap[chainId]?.[inputCurrencyId] ||
              inputCurrencyId === NATIVE[chainId]?.symbol
            }
          />

          <AutoColumn justify="space-between">
            <AutoRow
              justify={isExpertMode ? "space-between" : "center"}
            >
              <SwapUI.SwitchButton
                onClick={() => {
                  setApprovalSubmitted(false); // reset 2 step UI for approvals
                  onSwitchTokens();
                  replaceBrowserHistory(
                    "inputCurrency",
                    outputCurrencyId
                  );
                  replaceBrowserHistory(
                    "outputCurrency",
                    inputCurrencyId
                  );
                }}
              />
              {recipient === null && !showWrap && isExpertMode ? (
                <Button
                  variant="text"
                  id="add-recipient-button"
                  onClick={() => onChangeRecipient("")}
                >
                  {t("+ Add a send (optional)")}
                </Button>
              ) : null}
            </AutoRow>
          </AutoColumn>
          <CurrencyInputPanel
            value={formattedAmounts[Field.OUTPUT]}
            onUserInput={handleTypeOutput}
            label={
              independentField === Field.INPUT && !showWrap && trade
                ? t("To (estimated)")
                : t("To")
            }
            showMaxButton={false}
            currency={currencies[Field.OUTPUT]}
            onCurrencySelect={handleOutputSelect}
            otherCurrency={currencies[Field.INPUT]}
            id="swap-currency-output"
            showCommonBases
            showBUSD={
              !!tokenMap[chainId]?.[outputCurrencyId] ||
              outputCurrencyId === NATIVE[chainId]?.symbol
            }
          />

          {isExpertMode && recipient !== null && !showWrap ? (
            <>
              <AutoRow
                justify="space-between"
                style={{ padding: "0 1rem" }}
              >
                <ArrowWrapper clickable={false}>
                  <ArrowDownIcon width="16px" />
                </ArrowWrapper>
                <Button
                  variant="text"
                  id="remove-recipient-button"
                  onClick={() => onChangeRecipient(null)}
                >
                  {t("- Remove send")}
                </Button>
              </AutoRow>
              <AddressInputPanel
                id="recipient"
                value={recipient}
                onChange={onChangeRecipient}
              />
            </>
          ) : null}
        </AutoColumn>
        <Box mt="1rem">
          <SwapCommitButton
            swapIsUnsupported={swapIsUnsupported}
            account={account}
            showWrap={showWrap}
            wrapInputError={wrapInputError}
            onWrap={onWrap}
            wrapType={wrapType}
            parsedIndepentFieldAmount={
              parsedAmounts[independentField]
            }
            approval={approval}
            approveCallback={approveCallback}
            approvalSubmitted={approvalSubmitted}
            currencies={currencies}
            isExpertMode={isExpertMode}
            trade={trade}
            swapInputError={swapInputError}
            currencyBalances={currencyBalances}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onUserInput={onUserInput}
          />
        </Box>
        {showWrap ? null : (
          <div style={{ marginTop: "10px" }}>
            <SwapUI.Info
              price={
                Boolean(trade) && (
                  <>
                    <SwapUI.InfoLabel>{t("Price")}</SwapUI.InfoLabel>
                    {isLoading ? (
                      <Skeleton width="100%" ml="8px" height="24px" />
                    ) : (
                      <SwapUI.TradePrice
                        price={trade?.executionPrice}
                      />
                    )}
                  </>
                )
              }
              allowedSlippage={allowedSlippage}
            />
          </div>
        )}
      </Wrapper>
      {!swapIsUnsupported ? (
        trade && (
          <AdvancedSwapDetailsDropdown
            pairs={trade?.route?.pairs}
            path={trade?.route.path}
            priceImpactWithoutFee={priceImpactWithoutFee}
            realizedLPFee={realizedLPFee}
            slippageAdjustedAmounts={slippageAdjustedAmounts}
            inputAmount={trade?.inputAmount}
            outputAmount={trade?.outputAmount}
            tradeType={trade?.tradeType}
          />
        )
      ) : (
        <UnsupportedCurrencyFooter
          currencies={[currencies.INPUT, currencies.OUTPUT]}
        />
      )}
    </>
  );
}
